import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import doulaStyles from "./about-doulas.module.css";
import {Col, Container, Row} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import VideoAudioControl from "../components/videoAudioControl";

const AboutDoulasPage = ({data}) => {
    return (
      <Layout className="site-content">
        <Container>

            <div className="workshop">
              <div className="title">
                <h1 className={globalStyle.entryTitle}>Dating Your Doula: How to Find the Right Birth or Postpartum Doula for you</h1>
              </div>

              <div className="workshop-content">
                <Row className="workshopDescription">
                  <Col xs={12} md={8} sm={12}>
                    <Row>
                      {data.vimeoVideos.edges.map(item => (
                      <Col key={item.node.id} className={doulaStyles.doulaVideo}>
                        <iframe id={item.node.id}
                          src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          allowFullScreen
                          title="Doula Video">
                        </iframe>
                        <VideoAudioControl></VideoAudioControl>
                      </Col>
                      ))}
                    </Row>
                  </Col>

                  <Col xs={12} md={4} sm={12}>
                    <h4>Things to know</h4>
                      <div className="topicsList">

                        <ul>
                          <li>Doulas can support you before your baby is here as well as in your postpartum time.</li>
                          <li>Common topics to work with a Doula on are Hospital Preparation, Postpartum Recovery, Breastfeeding
                            or Bottle Feeding, Newborn Care and Soothing as well as Safe Sleep and Nursery Setup.</li>
                          <li>You will be working very closely with your Doula through some of the most challenging moments
                            of your life so it is very important to choose a Doula that you vibe with.</li>
                          <li>Take your time to get to know a few Doulas until you find the right match.</li>
                          <li><Link className={[doulaStyles.meetTheDoulasLink, globalStyle.redirectLink].join(' ')} to={'/meet-the-doulas'}>Meet the Doulas</Link></li>
                        </ul>
                      </div>
                  </Col>
                </Row>

                <Row className={doulaStyles.doulaDescription}>
                  <Col>
                    <h5 className={doulaStyles.title}>What to consider</h5>

                    <span className={doulaStyles.subtitle}>1. CHEMISTRY </span>
                    <p>
                      Most importantly, go with your gut. A great practice in listening to your
                      intuition is to tune into your body and ask yourself: When I’m with this person how do I
                      feel? It really is like dating! If something doesn’t feel quite right, listen to that instinct. If
                      your body is screaming a resounding, “she’s the one,” definitely listen to that. Your
                      Doula’s main role in your experience is to help you FEEL safe, supported, and
                      empowered. A huge reason that you’re hiring her is for the energy and presence that
                      she brings to the experience. So tune into that sensation the moment you meet her and
                      make sure she’s bringing the energy that YOU need. Another great question to ask
                      yourself is: In the past, when I have gone through something intense or have been in
                      pain, what kind of support did I crave?</p>
                    <span className={doulaStyles.subtitle}>2. PRICE </span>
                    <p>
                      The average price range for a Doula is anywhere from $500 to $3000 per birth.
                      Usually, the reason for the huge range has to do with experience and what that person
                      has to offer in their packages. You’ll notice that Doula’s each have their own unique
                      offerings. Some Doulas provide the standard two prenatal visits, attending the birth and
                      one postpartum visit. Some Doulas include placenta encapsulation, prenatal yoga
                      classes, massage, acupuncture, coaching, lactation consulting, postnatal care etc. So
                      take into consideration your budget and what kind of services and support you are
                      looking for.
                    </p>
                    <span className={doulaStyles.subtitle}>3. PHILOSOPHY </span>
                    <p>
                      Take the time to learn about her birth philosophy. Some Doulas only
                      believe in unmedicated at-home births, some are only comfortable with hospital births
                      and others support all types of birth. Ask her what her birth philosophy is and make sure
                      you feel aligned with her beliefs. A Doula’s purpose is to support you in an unbiased and
                      non judgemental way, so look for someone that supports your way of birthing without
                      imposing their own beliefs. This also feels like a good spot to note that most people,
                      upon hearing the word Doula, immediately think hippy dippy, placenta encapsulating,
                      bathtub birth workers. If that’s your jam, there are plenty of us that fit that mold but there
                      are also a lot of us who are very practical, straightforward, advocates of the medical
                      model and plenty of us who fall somewhere between on that spectrum. The point being
                      that birth workers come in all shapes and sizes with different personalities and
                      philosophies, so there will definitely be someone out there that is right for you.
                    </p>
                    <span className={doulaStyles.subtitle}>4. SPECIALTY </span>
                    <p>
                      Doulas come from many diverse backgrounds and have different
                      life-informed experiences. Some Doulas are well versed in trauma informed care,
                      LGBTQ+ Inclusivity, body positivity, in working with women of color or women from
                      marginalized or underserved communities. However you identify and whatever your
                      background, it is important to choose a Doula who can understand, empathize with and
                      advocate for you! Also, some Doulas are very well versed and comfortable working with
                      complicated births, whether that be a health related issue, multiple births, VBAC, breech
                      babies, etc. So get a feel for your Doula’s experience and comfort level with complicated
                      births.
                    </p>
                    <span className={doulaStyles.subtitle}>5. AVAILABILITY </span>
                    <p>
                      Obviously your chosen Doula has to be available on your due date and
                      preferably two weeks before and two weeks after, just in case. Make sure your
                      schedules line up and make sure that your Doula has a backup or two in case of
                      emergency.
                    </p>

                  </Col>
                </Row>
              </div>
            </div>
        </Container>
      </Layout>
    )
};

export const query = graphql`
  query {
    vimeoVideos: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "doula"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
  }
`;

export default AboutDoulasPage
